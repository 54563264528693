@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700;800;900&display=swap');

body,html {
  margin: 0;
  font-size: 15px;
  letter-spacing: 0.5px;
  font-family: 'Maven Pro', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

#root{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
