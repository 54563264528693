.app{
  max-height: 700px;
  max-width: 412px;
  width: 375px;
  height: 700px;
  margin: auto;
  border: 1px solid lightgrey;
  border-radius: 10px;
  overflow: auto;
  box-shadow: 0px 0px 15px 6px #0000001a;
}

@media screen and (max-width: 415px) {
  .app {
    min-height: 100vh;
    max-width: 415px;
    width: 100%;
    height: 100vh;
    border: none;
    border-radius: 0px;
    overflow: unset;
    box-shadow: none;
  }
}